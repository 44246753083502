var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "secondary", dark: "", dense: "", height: "40" } },
        [
          _c("v-toolbar-title", { staticClass: "text-subtitle-1" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("v-spacer"),
          _vm._t("toolbar-actions")
        ],
        2
      ),
      !_vm.fullWidth
        ? _c(
            "v-card-text",
            { staticClass: "text-center" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.fullWidth ? _c("div", [_vm._t("default")], 2) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }