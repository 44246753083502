<template>
    <v-card outlined>
        <v-toolbar color="secondary" dark dense height="40">
            <v-toolbar-title class="text-subtitle-1">{{
                title
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="toolbar-actions"></slot>
        </v-toolbar>
        <v-card-text class="text-center" v-if="!fullWidth">
            <slot></slot>
        </v-card-text>
        <div v-if="fullWidth">
            <slot></slot>
        </div>
    </v-card>
</template>
<script>
export default {
    name: "card-tile",
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>